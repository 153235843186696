.text-dlt {
    opacity: 0.7;
  }
.df {
    display: flex;
    align-items: center;
}
.lock-unlock {
    font-size: 20px;
    margin-right: 0.375rem;
}
.lock-unlock .mdi-lock-open-outline {
    color: #61bb00;
}
.lock-unlock .mdi-lock-outline {
    color: #ff0000bd;
}
.tabSet ul.nav {
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: scroll;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    white-space: nowrap;
    white-space: -moz-nowrap!important;
    white-space: -hp-nowrap;
    white-space: -o-nowrap;
    display: block;
    overflow-y: hidden;
    margin: 8px 0;
    padding: 4px;
    background: #fff;
    border-radius: 5px;
}
.tabSet ul.nav li{
    display: inline-block;
}
.tabSet ul.nav li a{
    color: #000;
    font-size: 13px;
    box-shadow: inset 0 0 0 1px #eee;
    margin: 0 2px;
}
.tabSet ul.nav li a.active,
.tabSet ul.nav li a:hover{
    background: linear-gradient(180deg, #2A3A43 27%, #1C282D 83%);
    color: #fff;
}
.theme_accordion .btn-link{
    background: #fff;
    color: #223037;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    border-bottom: 1px solid #202e34;
}
.theme_accordion .card-header{
    padding: 0;
}
/* @import '~ngx-toastr/toastr'; */

.up {
    background-color: #FFEB3B;
}

.down {
    background-color: #FFEB3B;
}

.lay span, .back span {
    display: block;
    text-align: center;
}

.text-green {
    color: #25b31d  !important;
    font-weight: 900 !important
}
.text-red {
    color: #ff4646 !important;
    font-weight: 900 !important
}
.no-overlay #overlay {
    background: transparent !important;
}

.full-overlay .loader {
    min-height: auto !important;
    position: initial !important;
}

iframe {
    width: 100%;
    height: 100%;
    min-height: 165px;
}
@media (max-width: 767px) {
    iframe{
        min-height: 205px;
    }
}

.active-tv, .nav-tabs .nav-link.active .active-pan {
    color: #ffb80c;
}

.greenbg{
background: #8dd2f0!important;
}
.redbg{
background: #feafb2!important;
}
.redbg:hover td, .greenbg:hover td{
background: #e6f7ff;
}

/* PAGINATION CSS */
.pagination {justify-content: flex-end;}

.pagination nav{
    /* margin:0 auto; */
    padding-top:10px;
  }
  .pagination.pagination-circle .page-item.active .page-link {
      border-radius: 50%;
  }
  .pagination .page-item .page-link:hover {
      -webkit-transition: all .3s linear;
      transition: all .3s linear;
      border-radius: .125rem;
      background-color: #eee;
  }
  .pagination.pg-blue .page-item.active .page-link:hover {
      background-color: #272c33;
  }
  .pagination .page-item.active .page-link {
      -webkit-transition: all .2s linear;
      transition: all .2s linear;
      border-radius: .125rem;
      background-color: #272c33;
      color: #fff;
  }
  .pagination .page-item .page-link {
      -webkit-transition: all .3s linear;
      transition: all .3s linear;
      outline: 0;
      border: 0;
      background-color: transparent;
      font-size: .9rem;
      color: #999;
      font-weight:bold;
  }
  .pagination.pagination-circle .page-item .page-link:hover {
      border-radius: 50%;
  }
  .pagination.pagination-circle .page-item .page-link {
      margin-left: 2px;
      margin-right: 2px;
      border-radius: 50%;
  }
  .pagination .page-item.active .page-link{
      box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
  }
  .page-link{
    border:none!important;
  }

/* PAGINATION CSS */

#live.video {
    min-height: 300px;
    width: 100%;
}

@media (max-width: 767px) {
    .show-mb-market {
        display: block;
    }
    .hide-mb-market {
        display: none;
    }
}

.oddsEventlist .img-fluid {
    padding-right: 2px;
}

.d-flex-end {
    display: flex;
    justify-content: end;
}